import Caption from '@algbra/ui/components/Caption/Caption'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { getIconByName } from '@algbra/ui/icons'
import { resolveAsImage } from '@algbra/ui/resolvers'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import ContentBox from '../components/ContentBox/ContentBox'
import MetaTags from '../components/MetaTags/MetaTags'
import PageLayout from '../components/PageLayout/PageLayout'
import { RichText } from '../components/RichText/RichText'
import { getMetaTags } from '../utils/metaTags'
import ZoneInlineWaitlist from '../zones/InlineWaitlist/InlineWaitlist'
import ZoneLatestNews from '../zones/LatestNews/LatestNews'
import styles from './news-detail.module.scss'

export function Author({ author }) {
  return (
    <ContentBox size="small">
      <p className={styles.author}>Author: {author}</p>
    </ContentBox>
  )
}

export default function LayoutNewsDetail({ pageContext, data, location }) {
  const { date, newsImage, content, slug, author } = data.strapiGQL.newsArticle
  const shareUrl = `${location.origin}/news/${slug}/`
  const [isCopied, setCopied] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState(0)
  const [isHeaderTiny, setHeaderIsTiny] = useState(false)
  const pageRef = useRef(null)
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [isCopied])

  useEffect(() => {
    function getScrollSize() {
      if (pageRef.current) {
        const scrollHeight = pageRef.current.clientHeight
        const currentScrollHeight = window.pageYOffset
        setScrollHeight((currentScrollHeight / scrollHeight) * 100)
        setHeaderIsTiny(currentScrollHeight > 90)
      }
    }
    document.addEventListener('scroll', getScrollSize)

    return () => {
      document.removeEventListener('scroll', getScrollSize)
    }
  })
  return (
    <PageLayout>
      <div
        className={classNames(styles.progress, {
          [styles.progressTiny]: isHeaderTiny,
        })}
      >
        <div
          className={styles.current}
          style={{ width: `${scrollHeight}%` }}
        ></div>
      </div>
      <MetaTags {...getMetaTags(pageContext)} />
      <Container className={styles.page}>
        <div ref={pageRef}>
          <Whitespace as="header" className={styles.header}>
            <Heading level={1} type="xl">
              {pageContext.title}
            </Heading>
            <Caption type="md" className={styles.date}>
              {dayjs(date).format('D MMMM YYYY')}
            </Caption>
          </Whitespace>
          <GatsbyImage
            image={resolveAsImage(newsImage)}
            alt=""
            className={styles.image}
            role="presentation"
            loading="lazy"
          />
          <Whitespace className={styles.contentArea}>
            <div className={styles.share}>
              <FreeText type="sm" className={styles.shareLabel}>
                Share
              </FreeText>
              <FacebookShareButton url={shareUrl} className={styles.iconBox}>
                <Icon icon={getIconByName('facebook')} />
              </FacebookShareButton>
              <LinkedinShareButton url={shareUrl} className={styles.iconBox}>
                <Icon icon={getIconByName('linkedin')} />
              </LinkedinShareButton>
              <TwitterShareButton url={shareUrl} className={styles.iconBox}>
                <Icon icon={getIconByName('twitter')} />
              </TwitterShareButton>
              <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
                <div className={styles.iconBox}>
                  <Icon icon={getIconByName('link')} />
                  <span
                    className={classNames(styles.copied, {
                      [styles.hasCopied]: isCopied,
                    })}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <RichText content={content} />
            { author && 
              <Author author={author} />
            }
          </Whitespace>
        </div>
      </Container>
      <ZoneLatestNews
        __typename="StrapiGQL_ComponentZonesLatestNews"
        title="Latest news"
        id="news-detail"
      />
      <ZoneInlineWaitlist
        __typename="StrapiGQL_ComponentZonesInlineWaitlist"
        title="Latest news"
      />
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      newsArticle(id: $id) {
        ...StrapiGQL_NewsArticlesFragment
        newsImage {
          __typename
          id
          url
          ext
          resolved {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: WEBP
                width: 970
                webpOptions: { quality: 100 }
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`
