import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './Caption.module.scss'

export type CaptionProps = {
  children: ReactNode
  as?: keyof HTMLElementTagNameMap
  type?: 'xl' | 'lg' | 'md'
  className?: string
  style?: React.CSSProperties
}
export default function Caption(props: CaptionProps) {
  const { as = 'div', className, children, type = 'lg', style } = props
  return React.createElement(
    as,
    {
      className: classNames(className, [styles[`type__${type}`]]),
      style,
    },
    children
  )
}
